import React from 'react';

function CompletedTick() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" fill="none" viewBox="0 0 17 17">
      <g stroke="#000" strokeLinecap="round" strokeLinejoin="round" clipPath="url(#clip0_7113_31415)">
        <path d="M11.291 6.945l-3.669 3.5-1.831-1.75"></path>
        <path d="M8.541 14.445a6 6 0 100-12 6 6 0 000 12z"></path>
      </g>
      <defs>
        <clipPath id="clip0_7113_31415">
          <path fill="#fff" d="M0 0H16V16H0z" transform="translate(.541 .445)"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default CompletedTick;
