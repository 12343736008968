import React from 'react';
import { getVideoUrl } from '../../utils/functions';

export default function VideoWatcher({ url }) {
  return (
    <div className="carousel pd-x-sm la-pn slideshow-container">
      <div className="carousel-stage">
        <div className="carousel-item carousel-video media ratio_21-9">
          <iframe
            width="560"
            height="315"
            src={`${getVideoUrl(url)}?autoplay=0&rel=0`}
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      </div>
    </div>
  );
}
