import React, { useState } from 'react';
import { graphql, Link } from 'gatsby';
import BodyClassName from 'react-body-classname';

import { useFetch } from '../../hooks';
import ShareIcon from '../../images/share-icon';
import { getVideoUrl } from '../../utils/functions';
import GCBook from '../../images/svg-gcbooks/GCBook.png';
import imageBG from '../../images/svg-gcbooks/promtBG.png';
import { AppContext } from '../../apollo/wrap-root-element';
import Backdrop from '../../components/accessories/backdrop';
import VideoWatcher from '../../components/Shop/VideoWatcher';
import ShareMenu from '../../components/accessories/shareMenu';
import CompletedTick from '../../images/svg-gcbooks/completed-tick';
import UpLeftArrow from '../../images/svg-gcbooks/arrow-up-right.svg';
import DefaultLayoutComponent from '../layouts/DefaultLayoutComponent';

export default function GcbookSingle({ data }) {
  const chaptersList = data?.allPrismicBookChapter?.nodes;
  const bookDesc = data?.prismicBooks?.data?.bookDescription?.text;
  const bookTitle = data?.prismicBooks?.data?.bookTitle?.text;

  const [showShareMenu, setShowShareMenu] = useState(false);
  const [deviceIsTab, setDeviceStatus] = useState(false);

  const { currentUserDetails } = React.useContext(AppContext);
  const [getCurrentUserCompletedBook, completedBooks, error, loading] = useFetch('Post');

  React.useEffect(() => {
    if (currentUserDetails) {
      currentUserDetails?.customer?.id &&
        getCurrentUserCompletedBook(`api/getCompletedBookByUserId`, {
          body: {
            userId: currentUserDetails.customer.id,
          },
        });
    }

    if (typeof window !== 'undefined') {
      let deviceWidth = window.matchMedia && window.matchMedia('(max-width: 768px)');
      setDeviceStatus(deviceWidth.matches);
      window.onresize = () => {
        setDeviceStatus(deviceWidth.matches);
      };
    }
  }, [currentUserDetails]);

  //share via native share

  let shareUrl;
  if (typeof window !== 'undefined') {
    shareUrl = window.location && window?.location?.href;
  }
  const shareData = {
    title: 'Share',
    text: '',
    url: '',
  };

  const handleShareNative = async () => {
    shareData.url = shareUrl;
    try {
      await navigator.share(shareData);
    } catch (err) {
      console.error(err);
    }
  };

  const isChapterRead = (chapterId) => {
    return completedBooks?.data?.some((item) => item.bookId === chapterId);
  };

  const trailer = data?.prismicBooks?.data?.trailer?.embed_url;

  return (
    <BodyClassName className="body-light page-watch">
      <DefaultLayoutComponent
        gcbooksList={true}
        timeline={true}
        gcbooks={true}
        title="The Great Controversy"
        backUrl="/"
      >
        <div className="gcBooksWrapper ">
          <div className="gcGradientBg">
            <section className="gcPagebg pd-x-lg">
              <div className="gcBgTitleAndIntro pd-x-lg">
                <div className="gcBreadCrumb">
                  <Link to="/">
                    <p className="gcBreadCrumbnav">Home /</p>
                  </Link>
                  <p className="gcBreadCrumbnav">Books /</p>
                  <p className="gcBreadCrumbCurrentnav ">{bookTitle}</p>
                </div>
                <h3 className="gcPageTitle">{bookTitle}</h3>
                <h6 className="gcShortDesc">{bookDesc}</h6>
                <div className="gcShareIcon  ">
                  <a href="https://www.freegc.org/" target="_blank" className="gc-link">
                    <img src={GCBook} />
                    <div>
                      <div className="gc-link-header-container">
                        <p className="gc-link-text link-header">Get Free Copy</p>
                        <img src={UpLeftArrow} />
                      </div>
                      <p className="gc-link-text link-sub">Full books are available as a physical copy.</p>
                    </div>
                  </a>
                  <div
                    onClick={() => {
                      deviceIsTab ? handleShareNative() : setShowShareMenu(true);
                    }}
                  >
                    <ShareIcon />
                  </div>
                  {showShareMenu ? <ShareMenu /> : ''}
                </div>
                <Backdrop
                  BackdropStatus={showShareMenu ? true : false}
                  onBackDropClick={setShowShareMenu}
                  backdropPayload={false}
                />
              </div>
            </section>
          </div>

          <div className=" wrapper wrapper-xl pd-x-md pd-0-res">
            <div className="gcPageInner">
              {currentUserDetails?.customer ? (
                ''
              ) : (
                <div className="gcSignupPromt">
                  <h6>
                    Please
                    <span className="bold">
                      <Link to="/signup">sign up </Link>
                    </span>
                    or
                    <span className="bold">
                      <Link to="/login">log in </Link>
                    </span>
                    to track and display your read progress.
                  </h6>
                  <div className="promtBG">
                    <img src={imageBG} />
                  </div>
                  <Link to="/signup">
                    <div type="button" className=" gc-signup-btn">
                      SIGN UP
                    </div>
                  </Link>
                </div>
              )}

              <h3 className="gcCardListHeader">CHAPTERS</h3>
              <section className="gccardListHolder">
                {chaptersList?.map((chapter, idx) => {
                  const title = chapter?.data?.chapterTitle?.text;
                  const duration = chapter?.data?.readDuration;
                  const imgUrl = chapter?.data?.thumbnailImage?.url;
                  return (
                    <div className="singleChapterContainer">
                      <Link to={`/books/${chapter?.uid}`}>
                        <div
                          className="media ratio_16-9_gc"
                          style={{
                            border: isChapterRead(chapter?.uid) ? '5px solid #FECB04' : '',
                          }}
                        >
                          <div className="gcCardImageContainer ">
                            {isChapterRead(chapter?.uid) && <div className="imageOverlay"></div>}

                            <img src={imgUrl} />
                          </div>
                          <div className="gcstatusNotification ">
                            {isChapterRead(chapter?.uid) ? (
                              <div className="gcCompleteStatus">
                                <CompletedTick />
                                <h6 className="gcstatusText">Completed</h6>
                              </div>
                            ) : (
                              <h6 className="gcstatusText">{duration} MIN READ</h6>
                            )}
                          </div>
                        </div>
                      </Link>
                      <div className="gcCardTitle">
                        <h6 className="gcCardChapter">Chapter {idx + 1}</h6>
                        <h6 className="gcCardheading">{title}</h6>
                      </div>
                    </div>
                  );
                })}
              </section>

              <div className="carousel-item carousel-video media ratio_21-9">
                <iframe
                  width="560"
                  height="315"
                  src={`${getVideoUrl(trailer)}?autoplay=0&rel=0`}
                  frameBorder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </DefaultLayoutComponent>
    </BodyClassName>
  );
}

export const query = graphql`
  query SingleBookPageQuery($uid: String!) {
    prismicBooks(uid: { eq: "the-great-controversy" }) {
      data {
        bookDescription: book_description {
          text
        }
        bookTitle: title {
          text
        }
        trailer {
          embed_url
          description
          author_name
        }
      }
    }
    allPrismicBookChapter(
      filter: { data: { chapter_of_book: { uid: { eq: $uid } } } }
      sort: { fields: data___display_priority, order: ASC }
    ) {
      nodes {
        data {
          chapterTitle: chapter_title {
            text
          }
          readDuration: read_duration
          thumbnailImage: thumbnail_image {
            url
          }
        }
        uid
      }
    }
  }
`;
