import React from 'react';

export default function ShareIcon() {
  return (
    <svg width={44} height={45} fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect y={0.395} width={44} height={44} rx={1} fill="#FECB04" />
      <path
        d="M14 26.395a4 4 0 1 0 0-8 4 4 0 0 0 0 8ZM28 35.395a4 4 0 1 0 0-8 4 4 0 0 0 0 8ZM28 17.395a4 4 0 1 0 0-8 4 4 0 0 0 0 8ZM24.638 15.557l-7.275 4.675M17.363 24.557l7.275 4.675"
        stroke="#000"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
